.checkout-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .checkout-title {
    text-align: center;
    color: #800000;
  }
  
  .event-info, .user-data, .ingressos-container, .payment-container {
    margin-bottom: 20px;
  }
  
  .user-data-form label, .ingresso-card label {
    display: block;
    margin-bottom: 10px;
  }
  
  input, select, button {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .add-button, .remove-button, .submit-button {
    background-color: #800000;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .add-button:hover, .remove-button:hover, .submit-button:hover {
    background-color: #a00000;
  }
  
  .total-value {
    text-align: right;
    font-size: 1.2em;
    font-weight: bold;
  }
  